import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql, Link } from 'gatsby';
import SocialGroups from './SocialGroups';

/* eslint no-alert: 0 */

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'form-name': this.getFormName(props),
      afterSubmit: false
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(this.state)
    })
      .then(() => {
        this.setState({ afterSubmit: true });
        window.ga('send', 'event', {
          eventCategory: 'Form',
          eventAction: 'Success',
          eventLabel: encode(this.state)
        });
        alert('Спасибо. Всё будет.');
      })
      .catch(() => {
        window.ga('send', 'event', {
          eventCategory: 'Form',
          eventAction: 'Error'
        });
      });

    e.preventDefault();
  };

  getFormName(props = this.props) {
    return `${props.name}-subscription-4`;
  }

  render() {
    const { afterSubmit } = this.state;

    return (
      <StaticQuery
        query={graphql`
          {
            backgroundImage: file(name: { eq: "background" }) {
              childImageSharp {
                sizes(maxWidth: 1600, quality: 100, toFormat: JPG) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
            footerImage: file(relativePath: { eq: "footer_bw.png" }) {
              childImageSharp {
                sizes(maxWidth: 824, quality: 85, toFormat: JPG) {
                  ...GatsbyImageSharpSizes_withWebp_noBase64
                }
              }
            }
            socialGroupVkImage: file(relativePath: { eq: "vk_sub_2x.png" }) {
              childImageSharp {
                sizes(maxWidth: 380, quality: 85, toFormat: JPG) {
                  ...GatsbyImageSharpSizes_withWebp_noBase64
                }
              }
            }
            socialGroupTelegramImage: file(relativePath: { eq: "telegram_sub_2x.png" }) {
              childImageSharp {
                sizes(maxWidth: 380, quality: 85, toFormat: JPG) {
                  ...GatsbyImageSharpSizes_withWebp_noBase64
                }
              }
            }
            socialGroupInstagramImage: file(relativePath: { eq: "instagram_sub_2x.png" }) {
              childImageSharp {
                sizes(maxWidth: 380, quality: 85, toFormat: JPG) {
                  ...GatsbyImageSharpSizes_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={props => (
          <footer className="footer">
            <div className="noise" />
            <Img
              className="footer_background"
              {...props.backgroundImage.childImageSharp}
              style={{ position: 'absolute' }}
            />
            <div className="footer_socials">
              <SocialGroups images={{ ...props }} />
            </div>
            <div className="footer_enter">
              <Img
                className="footer_image"
                style={{ position: 'absolute' }}
                {...props.footerImage.childImageSharp}
              />
              <div className="footer_welcome element">Следовать за чёрным кроликом?</div>
              <div className="footer_join">
                <div className="footer_row">
                  <form
                    name={this.getFormName()}
                    data-netlify
                    action="/thanks"
                    method="POST"
                    onSubmit={this.onSubmit}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value={this.getFormName()}
                      onChange={this.onChange}
                    />
                    <div className="element">
                      <input
                        name="email"
                        className="footer_input"
                        type="text"
                        placeholder="Ваш e-mail"
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <button className="footer_button button element" type="submit">
                      {afterSubmit ? 'Принято' : 'Да'}
                    </button>
                  </form>
                </div>
              </div>

              <div className="footer_menu element">{/* <MenuList /> */}</div>

              <div className="footer_copy">
                <div className="footer_links">
                  <Link className="footer_link" to="/">
                    Главная
                  </Link>
                </div>
                <div className="element">© Victor Raid&#39;s Dark Fields, MMXXIV</div>
                <div className="footer_answer element">42</div>
              </div>
            </div>
          </footer>
        )}
      />
    );
  }
}

export default Footer;
